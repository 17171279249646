import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/form-survey',
    name: 'form-survey',
    component: () => import('../views/FormSurvey.vue')
  },
  {
    path: '/survey-guru',
    name: 'survey-guru',
    component: () => import('../views/SurveyGuru.vue')
  },
  {
    path: '/hasil-survey-guru',
    name: 'hasil-survey-guru',
    component: () => import('../views/HasilSurveyGuru.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    children:[
      {
        path: 'hasil-survey',
        name: 'hasil',
        component: () => import('../components/HasilSurvey.vue'),
      },
      {
        path: 'arsip-survey',
        name: 'arsip',
        component: () => import('../components/ArsipSurvey.vue'),
      },
      {
        path: 'list-karantina',
        name: 'karantina',
        component: () => import('../components/ListKarantina.vue'),
      },
      {
        path: 'data-pokok',
        name: 'data-pokok',
        component: () => import('../components/DataPokok.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
