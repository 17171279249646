import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './registerServiceWorker'
import './quasar'
import axios from 'axios'
import VueExcelXlsx from "vue-excel-xlsx";


Vue.use(VueExcelXlsx);
Vue.use(require('vue-moment'));


// import money from 'v-money'

Vue.prototype.$http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
