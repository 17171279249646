<template>
  <div class="relative home">
    <div class="bg-blur"></div>
    <div class="fg-blur"></div>
    <q-card class="my-card absolute-center" v-show="!approval">
      <q-card-section class="text-center">
        <img src="/img/logo-sekolah.png" alt="">
      </q-card-section>
      <q-card-section class="text-center text-overline bg-blue-1 q-pa-xs">
        <!-- <strong> Sistem Monitoring COVID-19</strong> -->
         Sistem Monitoring COVID-19
      </q-card-section>
      <q-card-section>
        <q-btn unelevated style="background-color:#03913F" class="full-width text-white q-mb-md" label="SURVEY" @click="cekKode"  />
        <q-btn outline style="color:#03913F" class="full-width" label="DASHBOARD" @click="userInfo"  />
      </q-card-section>
      
    </q-card>

    <!-- dialog  survey -->
    <q-dialog v-model="approval" persistent>
      <q-card style="min-width:280px">
        <q-card-section class="text-white  text-bold row justify-between"  style="background-color:#232979;">
          Login Survey
          
          <q-btn flat v-close-popup round size="xs" icon="close" />
        </q-card-section>
        <q-card-section class="q-gutter-md">
          <q-input 
            autofocus
            outlined dense
            v-model="kode_unik"
            label="Kode Unik"
            @input="v=>{kode_unik=v.toUpperCase()}"
            
            />
          <!-- <q-select 
            outlined dense
            v-model="selected"
            label="Nama Petugas Survey"
            :options="listGuru"
            map-options
            /> -->

          <q-select
            outlined dense
            map-options
            v-model="selected"
            use-input
            hide-selected
            fill-input
            input-debounce="0"
            :options="filteredListGuru"
            @filter="filterFn"
            
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  No results
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </q-card-section>
        <q-card-section class="row justify-between q-pt-lg">
          <q-btn  :disable="!selected" no-caps outline style="color:#03913F;f" label="Survey Guru" @click="goToSurveyGuru" />
          <q-btn :disable="!selected" unelevated no-caps style="background-color:#03913F;color:white;" label="Survey Siswa" @click="goToSurveySiswa" />
        </q-card-section>
        <q-card-section v-if="selected.type == 'supervisor'" class="q-pt-none">
          <q-btn :disable="!selected" flat no-caps style="color:#03913F"  label="Monitor Hasil Survey Guru" class="full-width" @click="goToHasilSurvey" />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
const listGuru2 = []
export default {
  name: 'Home',
  data(){
    return{
      approval: false,
      kode_unik: '',
      selected: '',
      listGuru:[],
      filteredListGuru: []
    }
  },
  mounted(){
    this.$http.get('/list_nama_guru', {})
      .then (result => {
        this.listGuru = result.data
      })
  },
  methods:{
    filterFn (val, update, abort) {
      update(() => {
        const needle = val.toLowerCase()
        // console.log(this.listGuru)
        this.filteredListGuru = this.listGuru.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    cekKode(){
      this.$http.get('/kode_unik', {})
        .then (result => {
          if(localStorage.getItem('surveyor') && localStorage.getItem('kode_unik') == result.data.kode_unik){
            if(localStorage.getItem('tipe_survey') == 'siswa')
              this.$router.push('/form-survey')
            else if(localStorage.getItem('tipe_survey') == 'guru')
              this.$router.push('/survey-guru')
          }
          else{
            this.approval = true
          }
        })
    },
    goToSurveySiswa(){
      this.$http.get('/kode_unik', {})
        .then (result => {
          if(this.kode_unik == result.data.kode_unik){
            localStorage.setItem('kode_unik', this.kode_unik)
            localStorage.setItem('surveyor', this.selected.value)
            localStorage.setItem('tipe_survey', 'siswa')
            this.approval = false
            this.$router.push('/form-survey')
          }
          else{
            this.$q.notify({
              type: 'negative',
              message: `Kode yang Anda masukkan salah.`
            })
          }
        })
    },
    goToSurveyGuru(){
      this.$http.get('/kode_unik', {})
        .then (result => {
          if(this.kode_unik == result.data.kode_unik){
            localStorage.setItem('kode_unik', this.kode_unik)
            localStorage.setItem('surveyor', this.selected.value)
            localStorage.setItem('tipe_survey', 'guru')
            this.approval = false
            this.$router.push('/survey-guru')
          }
          else{
            this.$q.notify({
              type: 'negative',
              message: `Kode yang Anda masukkan salah.`
            })
          }
        })
    },
    goToHasilSurvey(){
      this.$http.get('/kode_unik', {})
        .then (result => {
          if(this.kode_unik == result.data.kode_unik){
            localStorage.setItem('kode_unik', this.kode_unik)
            localStorage.setItem('surveyor', this.selected.value)
            localStorage.setItem('tipe_survey', 'guru')
            this.approval = false
            this.$router.push('/hasil-survey-guru')
          }
          else{
            this.$q.notify({
              type: 'negative',
              message: `Kode yang Anda masukkan salah.`
            })
          }
        })
    },
    userInfo(){
      var token = localStorage.getItem('token')
      this.$http.get('/user/info', {headers: { Authorization: "Bearer " + token}})
        .then (result => {
          this.$router.push('/dashboard')
          })
        .catch(error => {
          this.$router.push('/login')
        })
    }
  },
}
</script>
<style lang="scss" scoped>
.my-card{
  min-width: 300px;
  backdrop-filter: blur(10px);
  // background-color: red;
}
.home {

}
.bg-blur {
  width: 100vw;
  height: 100vh;
  background-image: url("/img/drone.jpg");
  position: absolute;
}
.fg-blur {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px) brightness(.5);
  position: absolute;

}
</style>
