import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=6c1d624c&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"
import style0 from "./Home.vue?vue&type=style&index=0&id=6c1d624c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c1d624c",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QBtn,QDialog,QInput,QSelect,QItem,QItemSection});qInstall(component, 'directives', {ClosePopup});
